import { Atom, Mod } from ":mods";

export const CARD_ITEM = {
  id: "1",
  title: "Resource Name",
  desription: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
  image: {
    id: "1",
    src: "",
    alt: "temp",
    width: 222,
    height: 224,
  },
};

export const ROUTES = {
  foundation: (id?: string | number) => `/foundation/resources/${id ?? ":id"}`,
};

export const ANCHORES = {
  side: {
    admin: {
      foundation: {
        title: "Edit Resources",
        to: ROUTES.foundation(1),
        icon: {
          idle: "icon-local-nav-side:resources-idle",
          active: "icon-local-nav-side:resources-active",
        },
      } as Atom.Route.model.AnchorItem,
    },
    student: {
      foundation: {
        title: "Resources",
        to: ROUTES.foundation(1),
        icon: {
          idle: "icon-local-nav-side:resources-idle",
          active: "icon-local-nav-side:resources-active",
        },
      } as Atom.Route.model.AnchorItem,
    },
  },
};
